
  
  .dropzone {
    text-align: center;
    padding: 20px;
    border: 2px dashed #D1152D;
    background-color: #fafafa;
    color: #bdbdbd;
  
    margin-bottom: 20px;
  }
  
  .accept {
    border-color: #107c10 !important;
  }
  
  .reject {
    border-color: #d83b01 !important;
  }
  